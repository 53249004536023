export default {
  methods: {
    sendAcknowledgeReport (installationId, hostId, triggerId, severity, action, message, manualClose) {
          
          let report = {
            'InstallationId': installationId,
            'HostId': hostId,
            'TriggerId': triggerId,
            'Severity': severity,
            'Message': message,
            'Action': action,
            'ManualClose': manualClose
          };

          return this.axios.post('/MonitoringSystem/AcknowlegeProblem', report);
      },
    sendAcknowledgeAllReport (installationId, message, action, severity) {
          
        let report = {
          'InstallationId': installationId,
          'Message': message,
          'Severity': severity,
          'Action': action
        };

        return this.axios.post('/MonitoringSystem/AcknowlegeAllProblem', report);
    },
    sendAcknowledgeSelectedReport (message, selectedTriggers, action, severity) {
        
      let report = {
        'Message': message,
        'SelectedTriggers': selectedTriggers,
        'Severity': severity,
        'Action': action
      };

      return this.axios.post('/MonitoringSystem/AcknowlegeSelectedProblem', report);
    }
  }
}