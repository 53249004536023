<template>
  <Portlet
    :title="portletTilte"
    icon="band-aid"
  >
    <div class="acknowledgeProblem px-4">
      <LoadingPlaceholder v-if="loading" />
      <template v-else>
        <div
          v-if="trigger"
          class="row"
        >
          <div class="col-12 col-sm-9">
            <div class="form-group m-form__group">
              <label>{{ $t('message') }}</label>
              <textarea
                v-model="message"
                v-focus
                :class="['form-control m-input', { 'is-invalid': errors.has(`Message`) }]"
                placeholder=""
                @keydown.ctrl.enter="acknowledge()"
              />
              <span
                v-show="errors.has(`Message`)"
                class="badge badge-danger"
              >{{ errors.first(`Message`) }}</span>
            </div>
            <div class="row">
              <div class="form-check form-check-inline mb-3">
                <input
                  id="manualCloseCheckbox"
                  v-model="manualClose"
                  class="form-check-input"
                  type="checkbox"
                  :disabled="trigger.manualClose == '0'"
                >
                <label
                  class="form-check-label"
                  for="manualCloseCheckbox"
                >Close Problem</label>
              </div>
            </div>
            <div class="row">
              <div class="form-check form-check-inline mb-3">
                <input
                  id="changeSeverityCheckbox"
                  v-model="changeServerity"
                  class="form-check-input"
                  type="checkbox"
                >
                <label
                  class="form-check-label"
                  for="changeSeverityCheckbox"
                >Change Severity</label>
              </div>
              <select
                v-if="changeServerity"
                id="converterTypes"
                v-model="trigger.priority"
                class="form-control mb-4"
              >
                <option
                  v-for="(severity, index) in severities"
                  :key="`converterType-converterTypeOption-${ index }`"
                  :value="index+''"
                >
                  {{ severity }}
                </option>
              </select>
            </div>
            <div
              v-if="trigger.acknowledged"
              class="row"
            >
              <div class="form-check form-check-inline mb-3">
                <input
                  id="unacknowledgeCheckbox"
                  v-model="unacknowledge"
                  class="form-check-input"
                  type="checkbox"
                >
                <label
                  class="form-check-label"
                  for="unacknowledgeCheckbox"
                >Unacknowledge</label>
              </div>
            </div>
            <div
              class="row"
              style="justify-content: end;"
            >
              <button
                class="btn btn-primary float-right mr-2"
                @click.prevent="acknowledge()"
              >
                {{ trigger.acknowledged ? $t('update') : $t('acknowledge') }}
              </button>
            </div>
            <div class="clearfix" />
          </div>
          <div class="col-12 col-sm-3">
            <label>{{ $t('possibleMessages') }}</label>
            <button
              v-for="(item, index) in messages"
              :key="`acknowledgeProblem-messageButton-${ index }`"
              class="btn btn-block btn-secondary"
              @click="setMessage(item.key)"
            >
              {{ item.key }}
            </button>
          </div>
        </div>
        <template v-else>
          {{ $t('noDataAvailable') }}
        </template>
      </template>
    </div>
  </Portlet>
</template>

<script>
import acknowledgeReportAdapter from '@/adapter/acknowledgeReportAdapter.js';
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: "AcknowledgeProblem",
  mixins: [
    acknowledgeReportAdapter,
    errorMixin
  ],
  props: {
    triggerId: {
      type: String,
      required: true
    },
    installationId: {
      type: String,
      required: true
    },
    source: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      trigger: null,
      loading: true,
      message: "",
      action: 0,
      messages: null,
      installation: null,
      manualClose: null,
      changeServerity: false,
      unacknowledge: false,
      severities: ['Not classified', 'Information', 'Warning', 'Average', 'High', 'Disaster']
    }
  },
  computed: {
    portletTilte () {
      if (this.installation == null) {
        return 'Acknowledge';
      } else {
        return `Acknowledge: ${ this.installation.name }`;
      }
    }
  },
  created () {
    this.getTrigger();
    this.getInstallation();
    this.getAcknowledgeMessages();
  },
  methods: {
    setMessage (key) {
      let lowerCaseKey = key.toLowerCase();
      let messageToSet = this.messages.find(message => {
        return message.key.toLowerCase() === lowerCaseKey;
      });
      if (messageToSet == null) {
        return;
      }
      this.message = messageToSet.message;
    },
    getTrigger: function () {
      this.loading = true;
      this.axios.post(`/MonitoringSystem/GetTrigger?triggerId=${ this.triggerId }`)
        .then((response) => {
          if (response == null) {
            return;
          }
          this.trigger = response.data;
        });
    },
    getInstallation: function () {
      this.loading = true;
      this.axios.get(`/Installation/Get?Id=${ this.installationId }`)
        .then((response) => {
          if (response == null) {
            return;
          }
          this.installation = response.data;
        });
    },
    getAcknowledgeMessages: function () {
      this.loading = true;
      this.axios.get('/AcknowledgeMessages/GetAllAcknowledgeMessages')
        .then((response) => {
          if (response == null) {
            return;
          }
          this.messages = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // acknowledge all events of this trigger
    async acknowledgeAndCreateSubtask () {
      await this.createSubtask();
      await this.acknowledge();
    },
    async acknowledge () {
      this.loading = true;
      
      if(this.trigger.manualClose == '1' && this.manualClose == null) {
        this.manualClose = false;
      }

      this.action = 0;
      if(this.message) {
        this.action += 4
      }

      if(this.changeServerity) {
        this.action += 8;
      }

      if(this.manualClose) {
        this.action += 1;
      }

      if(!this.trigger.acknowledged) {
        this.action += 2;
      }
      else if(this.unacknowledge) {
        this.action += 16;
      }

      let acknowledgeRequest = this.sendAcknowledgeReport(this.installationId, this.trigger.hosts[0].hostId, this.triggerId, this.trigger.priority, this.action, this.message, this.manualClose);
      await acknowledgeRequest.then(() => {
          this.error_clear();
          
          if (this.source === 'problem') {
            this.$router.push({path: `/problem`});
            return;
          }
          this.$router.push({path: `/installation/${ this.installationId }`});
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
          this.loading = false;
      });
    },
    async createSubtask () {
      let subtaskDto = {
        'parentIssueId': this.installationId,
        'summary': this.message 
      };
      await this.axios.post('/Issue/CreateSubtask', subtaskDto)
        .then((response) => {
          if (response == null) {
            return;
          }
        });
    }
  }
}
</script>

<style>
.acknowledgeProblem .sbx-google__submit {
  display: none;
}
.acknowledgeProblem .sbx-google__input,
.acknowledgeProblem .sbx-google__wrapper,
.acknowledgeProblem .searchbox.sbx-google {
  width: 100%;
}
.acknowledgeProblem .is-invalid .sbx-google__input {
  border: 1px solid red;
  box-shadow: none;
}
</style>
